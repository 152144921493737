import React, { Component, PureComponent } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faFlask, faChartLine, faSearchPlus, faShoppingCart, faWrench, faCheckCircle, faExclamationTriangle, faUser, faChartArea, faRocket, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';
import PortalErrorReporting from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPortal/HomepageErrorReporting';
import Slider from "react-slick";
import Auth from '@aws-amplify/auth'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Legend, Cell } from 'recharts';
import KPIComponent from '@tra-sg/gatsby-theme-c360-portal/src/components/HomePageMainContent/KPIComponent';
import KPIGoalComponent from '@tra-sg/gatsby-theme-c360-portal/src/components/HomePageMainContent/KPIGoalComponent';


export default class HomePageMainContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kpiHovered: "Gross Sales Value (YTD)",
      modelHovered: null,
      username: null,
      selectedSolution: ["p2b"]
    }
  }

  componentDidMount() {
    let { username } = this.state;
    if (username == null) this.fetchCurrentUser();
    this.getSelectedSolution()
  }

  async fetchCurrentUser() {
    try {
      let user = await Auth.currentUserInfo();
      this.setState({username: user.attributes.email})
    } catch {
      this.setState({username: ''});
    }
  }

  abbreviateNumber(number) {
    // ref https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
    var SI_SYMBOL = ["", "K", "M", "B"];
    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }

  getSelectedSolution () {
    if (document.getElementsByName("solution_div")) {
      let solutionList = document.getElementsByName("solution_div")
      solutionList.forEach(solution => {
        if (this.state.selectedSolution.includes(solution.id.replace("_div", ""))) {
          solution.style.opacity = '1'
        } else {
          solution.style.opacity = '0.2'
        }
      })
    }
  }

  onClickSolutionBox (solution) {
    var { selectedSolution } = this.state;
    if ( selectedSolution.includes(solution) ) {
      // solution in the list, remove it
      selectedSolution = selectedSolution.filter((e) => e != solution)
    } else {
      // solution not in list, add it
      selectedSolution.push(solution)
    }
    this.setState({ selectedSolution });
  }

  getSolutionOpacity(solution) {
    let { selectedSolution } = this.state;
    return selectedSolution.includes(solution) ? 1 : 0.2
  }

  renderKPICard(title, values) {
    let { kpiHovered } = this.state;
    let renderValues = values || {current: 100000, projected: 200000};
    let style = kpiHovered == title ? {borderTop: '3px solid LightBlue', boxShadow: 'none'} : {boxShadow: 'none', backgroundColor: '#f7f7f7'};
    let color = title == "Customer Growth (Monthly)" ? 'green' : 'red'

    let data = [
      { name: "Current", pv: values.current },
      { name: "Projected", pv: values.projected }
    ];

    return (
      <div className="tile is-4 card"
        onClick={() => this.setState({kpiHovered: title})}
        style={style}
      >
        <div className="card-content" style={{marginTop: "-10px", marginBottom: "-25px"}}>
          <div className="columns">
            <div className="column">
              <span className="is-size-3">{title == "Gross Sales Value (YTD)" ? "$" : ""}{this.abbreviateNumber(renderValues.current)}{title == "Customer Growth (Monthly)" ? "%" : ""}</span>
            </div>
            <div className="column">
              {title == "Customer Growth (Monthly)" ? <span className="has-text-success is-size-3"> <FontAwesomeIcon icon={faCheckCircle}/> </span> : <span className="has-text-danger is-size-3"> <FontAwesomeIcon icon={faExclamationTriangle}/> </span>}
            </div>
          </div>
          <p className="is-size-4" style={{marginTop: "-20px"}}>
            {title}
          </p>
          <br></br>
          <KPIComponent kpiType={title} color={color} data={data} kpiValue={values.target}/>
        </div>
      </div>
    )
  }

  renderDataDashboardPanel() {
    return (
      <div className="tile is-child panel">
        <div className="panel-heading">
          Dashboards
        </div>
        {/* TODO: Make the following item generated in loop */}

        <div className="panel-block">
          <div className="is-size-5">
            <span className="panel-icon is-size-4">
            <FontAwesomeIcon icon={faChartBar} />
            </span>
            <span>
              Data updated for <Link to="/tindahan/order-and-sales/">Orders & Sales</Link> dashboard.
            </span>
            <div className="is-size-7">
              On January 21, 2021 11:35
            </div>
          </div>
        </div>

      </div>
    )
  }

  renderDataSourcePanel() {
    return (
      <div className="tile is-child panel">
        <div className="panel-heading">
          Source Data
        </div>

        <div className="panel-block">
          <span className="panel-icon is-size-4">
          <FontAwesomeIcon icon={faCalendarPlus} />
          </span>
          <div>
            <p className="is-size-5">
              Received data for <u>eRTM Orders</u>
            </p>
            <p className="is-size-7">
              <code>s3://ulph-c360-upstream/ertm/order/</code> - on January 21, 2021 11:35
            </p>
          </div>
        </div>

      </div>
    )
  }

  renderModelDeployedPanel() {
    return (
      <div className="tile is-child panel">
        <div className="panel-heading">
          Deployed
        </div>
        {/* TODO: Make the following item generated in loop */}

        <div className="panel-block">
        <span className="panel-icon is-size-4">
          <FontAwesomeIcon icon={faSearchPlus} />
        </span>
        <div>
          <p className="is-size-5">P2B - Predictions available for February 2021</p>
        </div>
      </div>

      <div className="panel-block">
        <span className="panel-icon is-size-4">
          <FontAwesomeIcon icon={faChartLine} />
        </span>
        <div>
          <p className="is-size-5">P2B - Performance report available for January 2021</p>
        </div>
      </div>

      </div>
    )
  }


  renderDataSection() {
    return (
      <div className="column is-6 is-vertical">
        <div className="card">
          <div className="card-content">
            <p className="title"> Data Processing </p>
          </div>
        </div>
        <div className="tile is-vertical">
          <div className="tile is-parent">
            {this.renderDataDashboardPanel()}
          </div>
          <div className="tile is-parent">
            {this.renderDataSourcePanel()}
          </div>
        </div>
      </div>
    )
  }


  renderModelSection() {
    return (
      <div className="column is-6 is-vertical">
        <div className="card">
          <div className="card-content">
            <p className="title"> Models </p>
          </div>
        </div>
        <div className="tile is-vertical">
          <div className="tile is-parent">
            {this.renderModelDeployedPanel()}
          </div>
          {/* <div className="tile is-parent">
            {this.renderModelStagingPanel()}
          </div> */}
        </div>
      </div>
    )
  }

  renderKPIBanner() {
    let { kpiHovered } = this.state;
    return (
      <div>
        <div className="tile is-ancestor">
          {this.renderKPICard("Gross Sales Value (YTD)", {current: 984132000, projected: 3224561890})}
          {/* {this.renderKPICard("New Customers")} */}
          {this.renderKPICard("Customer Growth (Monthly)", {current: 12.2, projected: 24.8})}
          {/* {this.renderKPICard("Units Sold")} */}
          {this.renderKPICard("Order Frequency (Monthly)", {current: 0.87, projected: 1.9, target: 2.0})}
          {/* <button className="button is-outlined is-fullwidth">See KPI Performance Monitor</button> */}
        </div>
        { kpiHovered ? this.renderKPIBannerBody() : null}
        <br/>
        <div className="columns">
          <div className="column is-10"></div>
          <div className="column is-2">
            <Link className="button" to="campaign/kpi-performance-monitor/">See Full KPI Overview</Link>
          </div>
        </div>
      </div>
    )
  }

  renderKPIBannerBody() {
    let { kpiHovered } = this.state;
    return (
      <div  style={{boxShadow: "1px 1px 5px #999999 inset", padding: '30px', backgroundColor: '#f9f9f9'}}>
        <br></br>
        <div className="columns">
          <div className="column is-6 is-vertical">
            <div>
              <p className="is-size-5">Contribution to {kpiHovered}</p>
            </div>
            <div style={{height: '300px'}}>
              {this.renderContributionChart()}
            </div>
          </div>
          {this.renderContributingModel()}
        </div>
      </div>
    )

  }

  renderContributionChart() {
    let { modelHovered } = this.state;
    const data = [
      {
        name: 'Jan 2021',
        baseline: 3433,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Feb 2021',
        baseline: 4130,
        p2b: 1134,
        personalizedOfferings: 899,
        salesForecast: 765,
      },
      {
        name: 'Mar 2021',
        baseline: 4284,
        p2b: 1540,
        personalizedOfferings: 989,
        salesForecast: 812,
      },
      {
        name: 'Apr 2021',
        baseline: 1896,
        p2b: 885,
        personalizedOfferings: 890,
        salesForecast: 520,
      },
      {
        name: 'May 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Jun 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'July 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Aug 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Sep 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Oct 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Nov 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
      {
        name: 'Dec 2021',
        baseline: 4000,
        p2b: 1000,
        personalizedOfferings: 1200,
        salesForecast: 800,
      },
    ];
    return (
      <div>
        <KPIGoalComponent selectedSolution={this.state.selectedSolution}/>
      </div>
    );
  }

  renderContributingModel() {
    // TODO: change list based on selected KPI
    let { modelHovered } = this.state;
    return (
      <div className="column is-6">
        <div>
          <p className="is-size-5">Solutions</p>
        </div>
        <br></br>
          <div className="columns is-multiline">
            <div className="column is-6">
              <div className="card"
                style={{backgroundColor: 'lightyellow', opacity: this.getSolutionOpacity("p2b")}}
                onClick={() => this.setState({modelHovered: 'p2b'})}
              >
                <div className="card-header" style={{height: '15px', boxShadow: 'none'}}>
                  <p className="card-header-title"></p>
                  <div className="card-header-icon" style={{paddingRight: '2px'}}>
                    <Link to="/models/p2b/"><FontAwesomeIcon icon={faExternalLinkAlt} style={{paddingBottom: '5px'}}/></Link>
                  </div>
                </div>
                <div className="card-content" style={{paddingTop: '1rem'}} onClick={() => this.onClickSolutionBox("p2b")}>
                  <div className="columns">
                    <div className="column is-10">
                      <span className="is-size-6"><FontAwesomeIcon icon={faShoppingCart} style={{margin: 'auto'}}/></span>
                      <span className="is-size-6">  Propensity-to-Buy</span>
                      <span className="is-size-6" style={{ paddingLeft: "10%"}} data-tooltip="Deployed. Time: Feb 2021"> <FontAwesomeIcon icon={faRocket}/> </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-6">
              <div className="card"
                style={{backgroundColor: '#ffebed', opacity: this.getSolutionOpacity("personalize")}}
                onClick={() => this.setState({modelHovered: 'personalizedOfferings'})}
              >
                <div className="card-header" style={{height: '15px', boxShadow: 'none'}}>
                  <p className="card-header-title"></p>
                  <div className="card-header-icon" style={{paddingRight: '2px'}}>
                    <Link to="/models/personalized_offerings/"><FontAwesomeIcon icon={faExternalLinkAlt} style={{paddingBottom: '5px'}}/></Link>
                  </div>
                </div>
                <div className="card-content" style={{paddingTop: '1rem'}} onClick={() => this.onClickSolutionBox("personalize")}>
                  <span className="is-size-6"><FontAwesomeIcon icon={faUser} style={{margin: 'auto'}}/></span>
                  <span className="is-size-6">  Personalized Offerings</span>
                  <span className="is-size-6" style={{ paddingLeft: "10%"}} data-tooltip="Prospecting. Time: Apr 2021"> <FontAwesomeIcon icon={faFlask}/> </span>
                </div>
              </div>
            </div>
            <div className="column is-6">
              <div className="row">
                <div className="card"
                  style={{backgroundColor: 'LightBlue', opacity: this.getSolutionOpacity("salesforecast")}}
                  onClick={() => this.setState({modelHovered: 'salesForecast'})}
                >
                  <div className="card-header" style={{height: '15px', boxShadow: 'none'}}>
                    <p className="card-header-title"></p>
                    <div className="card-header-icon" style={{paddingRight: '2px'}}>
                      {/* <Link to="/models/p2b/"><FontAwesomeIcon icon={faExternalLinkAlt} style={{paddingBottom: '5px'}}/></Link> */}
                    </div>
                  </div>
                  <div className="card-content" style={{paddingTop: '1rem'}} onClick={() => this.onClickSolutionBox("salesforecast")}>
                    <span className="is-size-6"><FontAwesomeIcon icon={faChartArea} style={{margin: 'auto'}}/></span>
                    <span className="is-size-6">  Product Recommendation</span>
                    <span className="is-size-6" style={{ paddingLeft: "10%"}} data-tooltip="Prospecting. Time: May 2021"> <FontAwesomeIcon icon={faFlask}/> </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }

  renderActivity() {
    return (
      <div>
        <div className="columns is-multiline">
          {this.renderDataSection()}
          {this.renderModelSection()}
        </div>
      </div>
    )
  }

  renderGreetingsHeader() {
    let { username } = this.state;
    let titleStyle = username != null ? {transition: '1s ease', opacity: 1} : {transition: '1s ease', opacity: 0}
    return (
      <div className="hero">
        <div className="hero-body">
          <div className="columns">
            <div className="column is-6" style={titleStyle}>
              <p className="title">{username > "" ? `Hi there, ${username}!` : "Hi there!"}</p>
              <p className="subtitle">Here's what's happening in c360.ai</p>
            </div>
            <div className="column is-6" style={{overflow: 'hidden', height: '170px'}}>
             <img src="https://i.pinimg.com/originals/fe/d2/13/fed2132528c0b3cd88353f2d1d8d8a8c.png"
               style={{marginTop: '0px'}}
             />
           </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="container">
        {this.renderGreetingsHeader()}
        {this.renderKPIBanner()}
        <br/><hr/><br/>
        {this.renderActivity()}
      </div>
    )
  }
}
