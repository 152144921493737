import React, { useMemo, Component } from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  Text,
  CartesianGrid,
  ReferenceLine,
  Tooltip
} from "recharts";


export default class KPIComponent extends Component {
  constructor(props) {
    super(props);
  }

  getColor(kpiValue, data) {
    data.map((d, idx) => {
      if (d["pv"] > kpiValue) {
        return "green"
      }
    })
    return "red"
  }

  abbreviateNumber(number) {
    // ref https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
    var SI_SYMBOL = ["", "K", "M", "B"];
    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }

  getData() {
    // prioritize value on props, otherwise use mocks
    let { kpiType } = this.props
    let data = []
    let kpiValue = 0

    if (kpiType == "Gross Sales Value (YTD)") {
      data = [
        { name: "Current", pv: 984132000 },
        { name: "Projected", pv: 3224561890 }
      ];
      kpiValue = 3500000000
    } else if (kpiType == "Customer Growth (Monthly)") {
      data = [
        { name: "Current", pv: 12.2 },
        { name: "Projected", pv: 24.8 }
      ];
      kpiValue = 20
    } else if (kpiType == "Customer Lifetime Value") {
      data = [
        { name: "Current", pv: 1655 },
        { name: "Projected", pv: 2389 }
      ];
      kpiValue = 2500
    }

    return {
        data: this.props.data || data,
        kpiValue: this.props.kpiValue || kpiValue,
    }

  }

  render() {
    let { color } = this.props;
    let { data, kpiValue } = this.getData();

    let dataRange = kpiValue * 1.5

    return (
      <div>
      <ResponsiveContainer height={data.length * 50 + 10} width="100%">
              <BarChart
                  data={data}
                  margin={{top: 0, right: 40, left: 40, bottom: 20}}
                  layout="vertical"
                  maxBarSize={20}

              >
                  <XAxis
                      type="number"
                      axisLine={false}
                      stroke='#a0a0a0'
                      domain={[0, dataRange]}
                      strokeWidth={0.3}
                      dataKey={"pv"}
                      tickFormatter={tick => {
                        return this.abbreviateNumber(tick);
                      }}
                  />
                  <YAxis
                      type="category"
                      dataKey={"name"}
                      width={20}
                      tick={{fontSize: 12}}
                      tickFormatter={tick => {
                        return this.abbreviateNumber(tick);
                      }}
                  />
                  <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                  <ReferenceLine x={kpiValue} stroke={`${color}`} label={{ value: `Target: ${this.abbreviateNumber(kpiValue)}`, fill: `${color}`, fontSize: 12, position: 'right'}} />
                  <Bar
                      dataKey="pv"
                      animationDuration={1000}
                      barSize={10}
                  >
                  {data.map((d, idx) => {
                    return <Cell key={d["pv"]} fill={"#629efc"} />;
                  })}
                  </Bar>
              </BarChart>
          </ResponsiveContainer>
      </div>
    );
  };
}
